.Flows {
    font-family: inherit;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1680px;
    padding-top: 150px;
    width: 100%;
}

.Flows__overview {
    display: flex;
    flex-direction: column;
    font-size: calc(28px + 0.25vw);
    height: 600px;
    justify-content: center;
    overflow: hidden;
    padding-left: max(6vw, 32px);
    padding-right: max(6vw, 32px);
}

.Flows__overview-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-animation: fadeIn 0.75s ease-in;
    -moz-animation: fadeIn 0.75s ease-in;
    -ms-animation: fadeIn 0.75s ease-in;
    -o-animation: fadeIn 0.75s ease-in;
    animation: fadeIn 0.75s ease-in;
}

.Flows__overview-body {
    margin-top: 16px;
    margin-bottom: 48px;
}

.Flows__modal-prompt {
    border-left: 2px solid orange;
    font-family: inherit;
    font-size: calc(16px + 0.25vw);
    font-weight: unset;
    padding: 0px 16px 0px 16px;
    text-align: left;
}

.Flows__modal-body {
    font-family: inherit;
    font-size: calc(16px + 0.25vw);
    line-height: 1.5;
    text-align: left;
    white-space: pre-wrap;
}

.Flows__modal-last-updated {
    font-size: calc(12px + 0.25vw);
}

.Flows__list {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: calc(16px + 0.25vw);
    padding: 8px;
    -webkit-animation: fadeIn 1.25s ease-in;
    -moz-animation: fadeIn 1.25s ease-in;
    -ms-animation: fadeIn 1.25s ease-in;
    -o-animation: fadeIn 1.25s ease-in;
    animation: fadeIn 1.25s ease-in;
}

.Flows__empty-list {
    display: flex;
    flex-direction: column;
    font-size: calc(24px + 0.25vw);
    justify-content: center;
    overflow: hidden;
    padding-left: max(6vw, 32px);
    padding-right: max(6vw, 32px);
    -webkit-animation: fadeIn 1.25s ease-in;
    -moz-animation: fadeIn 1.25s ease-in;
    -ms-animation: fadeIn 1.25s ease-in;
    -o-animation: fadeIn 1.25s ease-in;
    animation: fadeIn 1.25s ease-in;
}

.Flows__item {
    align-items: flex-start;
    background-color: #4a4e69;
    box-sizing: border-box;
    border: 2vw solid #4a4e69;
    cursor: pointer;
    display: flex;
    height: 20vw;
    margin: 8px;
    max-width: 20vw;
    overflow: hidden;
    position: relative;
    width: 20vw;
    white-space: pre-wrap;
}

.Flows__daily-prompt-complete-indicator {
    background-color: #4a4e69;
    bottom: 0px;
    font-size: calc(10px + 0.25vw);
    right: 0px;
    padding: 8px;
    position: absolute;
    text-transform: capitalize;
    width: 18px;
}

.Flows__daily-prompt-complete-indicator svg {
    fill: white;
}

@media only screen and (max-width: 768px) {
    .Flows__list {
        justify-content: center;
    }

    .Flows__item {
        border: 6vw solid #4a4e69;
        height: 75vw;
        max-width: 75vw;
        width: 75vw;
    }
}

@-webkit-keyframes fadeIn {
    0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes fadeIn {
    0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes fadeIn {
    0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
    0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
