.Freemium__Ad {
    align-items: center;
    background: #bdc3c7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(#536976,#292E49);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(#536976,#292E49); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    z-index: 3;
}

.Freemium__Ad-container {
    color: white;
    padding: 0px 32px 32px 32px;
    z-index: 4;
}

.Freemium {
    display: flex;
    flex-direction: row;
}

.Freemium__child {
    position: relative;
    width: 50%;
}

.Freemium__floward-logo {
    width: 100%;
    z-index: 4;
}

.Freemium__floward-subtitle {
    font-family: 'Homemade Apple', cursive;
    font-size: calc(18px + 0.25vw);
    margin-bottom: 16px;
}

.Freemium__floward-feature {
    font-size: calc(16px + 0.25vw);
    font-weight: normal;
}

.Freemium__floward-icon {
    margin-right: calc(8px + 0.1vw);
}
.Freemium__signup {
    background-color: #0af;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: inherit;
    font-weight: bold;
    padding: 16px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .Freemium__Ad {
        height: 600px;
    }

    .Freemium {
        flex-direction: column;
    }

    .Freemium__child {
        width: 100%;
    }

    .App {
        height: 700px !important;
    }
}
