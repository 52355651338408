.Editor {
    background: transparent;
    border: none;
    bottom: 50%;
    box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    font-size: calc(16px + 0.25vw);
    max-width: 588px;
    outline: none;
    overflow: hidden;
    padding: 0 16px;
    position: absolute;
    resize: none;
    width: 100%;
    z-index: 1;
  }

.Editor--error {
    color: red;
    -webkit-animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    -moz-animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    -ms-animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    -o-animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

@-webkit-keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}
@-moz-keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}
@-o-keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}
@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}


@media only screen and (max-width: 768px) {
  .Editor {
    bottom: 40%;
  }
}
