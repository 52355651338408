.Toastify__toast--dailyPrompt {
  border-left: 3px solid orange;
  color: #1d1d1f;
  font-family: inherit;
}

.App {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 16px;
  height: 100vh;
  justify-content: center;
}

.App__daily-prompt-indicator {
  color: orange;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  margin-top: 14px;
}

.App__daily-prompt-indicator-time-info {
  color: #86868b;
  font-weight: normal;
}

.App__daily-prompt {
  background: #282c34;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  max-width: 588px;
  padding: 32px 16px;
  width: 100%;
  z-index: 3;
}

.App__daily-prompt-body {
  border-left: 2px solid orange;
  font-size: calc(24px + 0.25vw);
  line-height: 1.33;
  margin-bottom: 16px;
  padding: 0px 16px 0px 16px;
}

.App__daily-prompt-action {
  color: gray;
  font-size: calc(14px + 0.25vw);
}

.App__daily-prompt-podcast-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App__daily-prompt-podcast-content {
  text-align: center;
  border-radius: 8px;
  color: #fff;
  padding: 8px 16px;
  text-decoration: none;
  margin: 0px 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #7232c6;
}

.App__daily-prompt-podcast-content-title {
  font-size: 14px;
}

.App__daily-prompt-podcast-content-subtitle {
  font-size: 12px;
  font-style: italic;
}

.App__instructions {
  text-align: left;
}

.App__video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.App__video-container iframe,
.App__video-container object,
.App__video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App__software-update-notification {
  color: white;
}

.App__software-update-notification a {
  color: orange;
}

.App__black-owned-tag {
  background: #dd3e54; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #6be585,
    #dd3e54
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #6be585,
    #dd3e54
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  font-size: 16px;
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
}

body.swal2-shown > [aria-hidden="true"] {
  filter: blur(10px);
}

body > * {
  transition: 0.05s filter linear;
}

@media only screen and (max-width: 768px) {
  .App__daily-prompt-body {
    font-size: calc(18px + 0.25vw);
  }
}
