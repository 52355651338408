.ActionSheet__button {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 8px;
    border-top: 1px solid #d2d2d7;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: inherit;
    line-height: 44px;
    justify-content: flex-start !important;
    padding: 8px 16px;
    text-align: left;
    width: 100%;
}

@media (hover: hover) and (pointer: fine) {
    .ActionSheet__button:hover {
        background-color: #EEEEEE;
    }
}

.ActionSheet__button svg {
    fill: #545454; /* for text, this comes from Swal */
    margin-right: 8px;
}

.ActionSheet__trigger-button {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ActionSheet__trigger-button svg {
    margin-right: 8px;
}
