.Fog {
    bottom: calc(50% + calc(16px + 0.25vw));
    max-width: 588px;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.Fog__line-top {
    background: #282c34;
    height: 100vh;
}

.Fog__line {
    background: linear-gradient(0deg, rgba(40, 44, 52, 0.5), rgba(40, 44, 52, 1));
    height: calc(3 * (16px + 0.25vw));
}

@media only screen and (max-width: 768px) {
    .Fog {
        bottom: calc(40% + calc(16px + 0.25vw));
    }
}
