.Toolbar {
    box-sizing: border-box;
    display: flex;
    fill: white;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
}

.Toolbar svg {
    fill: inherit;
}

.Toolbar__left-side {
    display: flex;
    flex-direction: row;
}

.Toolbar__right-side {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Toolbar__button {
    align-items: center;
    border: none;
    background-color: transparent;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: inherit;
    justify-content: center;
    padding: 8px 16px;
}

.Toolbar__button svg {
    margin-right: 8px;
}

@media (hover: hover) and (pointer: fine) {
    .Toolbar__button:hover {
        background: linear-gradient(90deg, #141E30, #243B55);
        background-size: 400% 400%;
        color: #0af;
        fill: #0af;
    
        -webkit-animation: Pour 8s ease infinite;
        -moz-animation: Pour 8s ease infinite;
        -o-animation: Pour 8s ease infinite;
        animation: Pour 8s ease infinite;
    }
}

@-webkit-keyframes Pour {
    0%{background-position:0% 99%}
    50%{background-position:100% 2%}
    100%{background-position:0% 99%}
}
@-moz-keyframes Pour {
    0%{background-position:0% 99%}
    50%{background-position:100% 2%}
    100%{background-position:0% 99%}
}
@-o-keyframes Pour {
    0%{background-position:0% 99%}
    50%{background-position:100% 2%}
    100%{background-position:0% 99%}
}
@keyframes Pour {
    0%{background-position:0% 99%}
    50%{background-position:100% 2%}
    100%{background-position:0% 99%}
}
