.box {
    position: absolute;
    top: 0;
    transform: rotate(80deg); 
    left: 0;
}

.wave {
    position: absolute;
    opacity: .4;
    width: 1500px;
    height: 1300px;
    margin-left: -150px;
    margin-top: -250px;
    border-radius: 43%;
}

.wave--one {
    animation: rotate 28000ms infinite linear;
    opacity: .1;
    background: #0af;
}

.wave--two {
    animation: rotate 12000ms infinite linear;
    opacity: .1;
    background: black;
}

.wave--three {
    animation: rotate 30000ms infinite linear;
    opacity: .1;
    background-color: #0af;
}

@keyframes rotate {
    from { transform: rotate(0deg); }
    from { transform: rotate(360deg); }
}
